import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tooltip from "@mui/material/Tooltip"
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import { Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'

const propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired
}

const CreateProviderGrouping = ({ organizations }) => {
  const [name, setName] = useState('')
  const [organization, setOrganization] = useState('')
  const [sourceOrganization, setSourceOrganization] = useState('')
  const [providerGroupingOptions, setProviderGroupingOptions] = useState([])
  const [sourceProviderGrouping, setSourceProviderGrouping] = useState('')
  const [copyOrderPrefsAndSubs, setcopyOrderPrefsAndSubs] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    if (!name || !organization) {
        flash.error('Please fill in all required fields', 5000)
        return // Stop further submission logic
    }
    const formData = new FormData()
    formData.append("name", name)
    formData.append("organization_id", organization)
    formData.append("source_organization_id", sourceOrganization)
    formData.append("source_provider_grouping_id", sourceProviderGrouping.id)

    $.ajax({
      method: 'POST',
      url: '/root/provider_grouping/create',
      contentType: false,
      processData: false,
      data: formData,
    })
    .done(() => {
      flash.success(`Provider Grouping '${name}' was successfully Created`, 50000)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    })
    .fail((error) => {
      flash.error(`Error creating your Provider Grouping. ${error.responseJSON.error}`, 50000)
      console.error(error)
    })
  }

  useEffect(() => {
    if (sourceOrganization) {
      $.ajax({
        method: 'get',
        url: '/root/provider_grouping/provider_grouping_finder',
        dataType: 'json',
        contentType: 'application/json',
        data: { organization_id: sourceOrganization },
      })
      .done((response) => {
        setProviderGroupingOptions(Array.isArray(response.provider_grouping) ? response.provider_grouping : [])
      })
      .fail((error) => {
        flash.error(`Error fetching Provider Groupings. ${error.responseJSON.error}`, 5000)
        console.error(error)
      })
    }
}
, [sourceOrganization])

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        value={name}
        onChange={event => setName(event.target.value)}
        required
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <Autocomplete
        id="organization-options"
        options={organizations}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Organization" value={organization} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
        onChange={(event, value) => setOrganization(value.id)}
      />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={copyOrderPrefsAndSubs}
              onChange={event => setcopyOrderPrefsAndSubs(event.target.checked)}
              name="copyOrderPrefsAndSubs"
              color="primary"
            />
          }
          label="Select a source to copy order preferences and subscriptions from"
        />
        {copyOrderPrefsAndSubs && (
           <>
              <Autocomplete
                id="organization-options"
                options={organizations}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label=" Source Organization" value={sourceOrganization} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
                onChange={(event, value) => setSourceOrganization(value.id)}
              />
             <Tooltip title="Source: The provider group's order preferences to be copied from." arrow>
             <Autocomplete
               id="source-provider-grouping-options"
               options={providerGroupingOptions}
               getOptionLabel={(option) => option.name}
               renderInput={(params) => <TextField {...params} label="Source Provider Grouping" value={sourceProviderGrouping} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
               onChange={(event, value) => setSourceProviderGrouping(value)}
             />
             </Tooltip>
           </>
        )}
      </div>
      <Button type="submit" sx={{ mt: 2 }} variant="contained">Create</Button>
    </form>
  )
}

CreateProviderGrouping.propTypes = propTypes

export default CreateProviderGrouping